import { Component as Cp } from "react";
import exampleData from "../assets/exampleData";
import Mermaid from "./Mermaid/Mermaid";
import NavBar from "./NavBar/NavBar";
import Chat from "./Chat/Chat";
import mermaid from "mermaid";

type State = {
    data :string;
};

/**@once*/
export default class App extends Cp<{}, State>{
    constructor(props :{}){
        super(props);
        this.state = {
            data: exampleData.basic as string
        };
    }
    componentDidMount(){
        mermaid.initialize({
            fontFamily: `ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
            htmlLabels: false
        });
    }
    render(){
        return(
            <div style={{
                display: "flex",
                flexFlow: "row nowrap",
                height: `100dvh`,
                overflowY: "auto"
            }}>
                <NavBar updateData={(data :string)=>this.setState({data})} />
                <Mermaid id="mermaid-main" text={this.state.data} width={"calc(50dvw - 5.8rem)"} height="100dvh" />
                <Chat width={"calc(50dvw - 5.8rem)"} />
            </div>
        );
    }
}