import { Component as Cp, ReactNode } from "react";
import mainStyles from "../../css/main.module.css";
import styles from "./ChatHistory.module.css";
import { Button } from "@headlessui/react";
import { EllipsisOutlined } from "@ant-design/icons";

interface IChatHistory{
    title :string;
    lastAccess :string;
    id :string;
}

type State = {
    data :IChatHistory[];
};

/**@once */
export default class ChatHistory extends Cp<{}, State>{
    constructor(props :{}){
        super(props);
        this.state = {
            data: this.getData()
        };
    }
    getData = () :IChatHistory[]=>{
        return[
            {id: "1", lastAccess: "2024.12.3", title: "示例对话1"},
            {id: "2", lastAccess: "2024.12.3", title: "示例对话2"},
            {id: "3", lastAccess: "2024.12.3", title: "示例对话3"}
        ];
    }
    render(){
        const list = this.state.data.map(value=>(
            <div key={value.id} className={styles.listItem}>
                {value.title}
                <Button className={styles.more}><EllipsisOutlined /></Button>
            </div>
        ));
        return(
            <div className={styles.wrapper}>{list}</div>
        );
    }
}