import { Button, CloseButton, Dialog, DialogPanel, Textarea } from "@headlessui/react";
import mainStyles from "../../css/main.module.css";
import styles from "./NavBar.module.css";
import { useRef, useState } from "react";
import { FileAddOutlined } from "@ant-design/icons";
import ChatHistory from "./ChatHistory";

type Props = {
    updateData :(data :string)=>void;
};

/**@once*/
export default function NavBar(props :Props){
    const
        [uploadDialogOpen, setUploadDialogOpen] = useState(false),
        code = useRef<HTMLTextAreaElement>(null),
        file = useRef<HTMLInputElement>(null),
        input = ()=>{
            if(code.current!.value !== "") props.updateData(code.current!.value);
            else if(file.current!.files![0]){
                //console.log(file.current!.files![0]);
                const reader = new FileReader();
                reader.readAsText(file.current!.files![0]);
                reader.addEventListener("load", data=>props.updateData(data.target!.result! as string));
            }
        };
    return(
        <div className={styles.wrapper}>
            <Button className={`${mainStyles.noselect} ${styles.menuItem}`} onClick={()=>setUploadDialogOpen(true)}>
                <FileAddOutlined />
                <div>上传文档</div>
                <Dialog open={uploadDialogOpen} onClose={()=>setUploadDialogOpen(false)} className={`${mainStyles.fullScreen} ${styles.dialogWrapper}`}>
                    <DialogPanel>
                        <div className={styles.dialog}>
                            <div>粘贴 Mermaid 代码：</div>
                            <Textarea ref={code} className={styles.code} />
                            <hr />
                            <div>或选择文件：</div>
                            <input ref={file} type="file" />
                            <CloseButton className={styles.submit} onClick={input}><div>确定</div></CloseButton>
                        </div>
                    </DialogPanel>
                </Dialog>
            </Button>
            <hr style={{
                backgroundColor: "#aaa",
                margin: 0,
                height: "1px"
            }} />
            <div className={styles.chatHistoryTitle}><div>聊天</div></div>
            <ChatHistory />
        </div>
    );
}