import { Component as Cp } from "react";
import mainStyles from "../../css/main.module.css";
import styles from "./Chat.module.css";
import { Button } from "@headlessui/react";
import { SendOutlined, PlusOutlined } from "@ant-design/icons";
import Logo from "../../assets/logo.svg";

type Props = {
    width :string;
};

type State = {
    isInitial :boolean;
};

/**@once*/
export default class Chat extends Cp<Props, State>{
    constructor(props :Props){
        super(props);
        this.state = {
            isInitial: true
        };
    }
    render(){
        return(
            <div className={styles.wrapper} style={{
                width: this.props.width
            }}>
                <div className={styles.logoContainer}>
                    <Logo color="#4958c9b0" radius="4rem" />
                    <div className={`${mainStyles.noselect} ${styles.title}`}>简形 - 流程可视化平台</div>
                </div>
                <div className={styles.initialInputOuter}>
                    <div className={styles.titleWrapper}><h4>询问有关本文档的问题</h4></div>
                    <div className={styles.initialInputWrapper}>
                        <Button className={styles.more}><PlusOutlined /></Button>
                        <p contentEditable className={styles.initialInput} />
                        <Button className={styles.submit}><SendOutlined /></Button>
                    </div>
                </div>
            </div>
        );
    }
}